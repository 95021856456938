<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="modal_form_proyeccionHidrocarburos_export"
      style="background-color: #00000082"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Resumen Proyecciones
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="">Fecha Inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_ini"
                        :class="
                          $v.filtros.fecha_ini.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <label for="">Fecha Final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="filtros.fecha_fin"
                        :class="
                          $v.filtros.fecha_fin.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                      />
                    </div>

                    <div class="form-group col-md-12">
                      <label for="bloque">Bloque</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="bloques"
                        placeholder="Bloque"
                        label="nombre"
                        :options="listasForms.bloques"
                        @input="selectBloque()"
                        multiple
                        :disabled="
                          !filtros.fecha_ini == null || !filtros.fecha_fin
                        "
                      >
                      </v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal-footer justify-content-between"
            v-if="
              $store.getters.can(
                'hidrocarburos.proyeccionHidrocarburos.export'
              ) && !$v.filtros.$invalid
            "
          >
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ProyeccionHidrocarburosExport",
  components: {
    vSelect,
  },
  data() {
    return {
      bloques: [],
      filtros: {
        fecha_ini: null,
        fecha_fin: null,
        bloques_id: [],
      },
      listasForms: {
        bloques: [],
      },
    };
  },
  validations: {
    filtros: {
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
    },
  },
  methods: {
    limpiarFiltros() {
      this.bloques = [];
      this.filtros = {
        fecha_ini: null,
        fecha_fin: null,
        bloques_id: [],
      };
    },

    validaFechas() {
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        var fecha_menor = new Date(this.filtros.fecha_ini);
        var fecha_mayor = new Date(this.filtros.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
        this.getBloques();
      }
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        fecha_menor = new Date(this.filtros.fecha_ini).getTime();
        fecha_mayor = new Date(this.filtros.fecha_fin).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    getBloques() {
      let data = {
        fecha_ini: this.filtros.fecha_ini,
        fecha_fin: this.filtros.fecha_fin,
      };
      axios
        .get("/api/hidrocarburos/proyeccionHidrocarburos/listaBloques", {
          params: data,
        })
        .then((response) => {
          this.listasForms.bloques = response.data;
        });
    },

    selectBloque() {
      this.filtros.bloques_id = null;
      if (this.bloques) {
        this.filtros.bloques_id = this.bloques.map((b) => b.id);
      }
    },

    generarListadoExcel() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/proyeccionHidrocarburos/export",
        data: {
          filtros: this.filtros,
        },
      })
        .then((response) => {
          this.$parent.cargando = false;
          let data = response.data;
          if (!response.data.error) {
            location.href = data.url;
            this.$swal({
              icon: "success",
              title: response.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
